import _ from "lodash";
import React, { useState } from "react";
import { htmlToReact } from "../utils";

const ContactSection = props => {
  const [message, setMessage] = useState("");

  return (
    <section className="section container-md">
      <p className="section__copy">
        {_.get(props, "section.text", null) &&
          htmlToReact(_.get(props, "section.text", null))}
      </p>
      <form
        name="contactForm"
        id="contactForm"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="https://mensindezorg.netlify.app/contact/"
      >
        <div className="sr-only">
          <label id="honeypot-label" htmlFor="honeypot">
            Don't fill this out if you're human:
          </label>
          <input
            aria-labelledby="honeypot-label"
            id="honeypot"
            name="bot-field"
          />
        </div>
        <input
          aria-labelledby="honeypot-label"
          type="hidden"
          name="form-name"
          value="contactForm"
        />
        <div className="tr-container">
          <div className="row">
            <div className="col-12 mb-3">
              <label id="name-label" htmlFor="name" className="form-label">
                Naam
              </label>
              <input
                aria-labelledby="name-label"
                type="text"
                name="name"
                id="name"
                placeholder={_.get(props, "section.namePlaceholder", null)}
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label id="email-label" htmlFor="email" className="form-label">
                E-mailadres
              </label>
              <input
                aria-labelledby="email-label"
                type="email"
                name="email"
                id="email"
                placeholder={_.get(props, "section.emailPlaceholder", null)}
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label
                id="telephone-label"
                htmlFor="telephone"
                className="form-label"
              >
                Telefoonnummer
              </label>
              <input
                aria-labelledby="telephone-label"
                type="tel"
                name="telephone"
                id="telephone"
                placeholder={_.get(props, "section.telephonePlaceholder", null)}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 mb-3">
            <label id="message-label" htmlFor="message" className="form-label">
              Bericht ({message.length} / 500)
            </label>
            <textarea
              aria-labelledby="message-label"
              name="message"
              id="message"
              rows="5"
              maxLength="500"
              placeholder={_.get(props, "section.messagePlaceholder", null)}
              className="form-control"
              value={message}
              onChange={event => {
                const value = event.target.value;
                if (value.length > 500) {
                  return;
                }

                setMessage(value);
              }}
            />
          </div>
          <div className="form-submit">
            <button
              type="submit"
              className="aheto-btn js-video-btn aheto-btn--primary aheto-btn--large"
            >
              Versturen!
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ContactSection;
