import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactSection from "../components/ContactSection";
import marked from "marked";
import Link from "../utils/link";
import RegisterForm from "../components/RegisterForm";

const Page = ({ data, isPreview }) => {
  const { frontmatter } = data.markdownRemark;
  const seasons = Array.from(
    new Set(
      data.allMarkdownRemark.edges.map(
        podcasts => podcasts.node.frontmatter.season
      )
    )
  );

  if (typeof frontmatter.text !== "undefined" && frontmatter.text !== null) {
    var markedText = marked(frontmatter.text);
  }

  return (
    <Layout title={frontmatter.title}>
      {!isPreview && <SEO title={frontmatter.title} />}
      {frontmatter.pageKey === "event" ? (
        <div>
          <section className="landing">
            <div className="inner-layer landing-inner">
              <div className="vertical-line"></div>
              <h1 className="mt-5 fs-3 text-active fw-bold fls-0 text-gradient">
                MENS IN DE ZORG EVENT
              </h1>
              <div className="background-dark py-3 px-5 mt-4">
                <h2 className="text-center mt-3 mx-3">
                  Bi-cultureel Leiderschap
                </h2>
                <h3 className="text-center mt-1 fs-5">
                  voor leiders in de zorg die het beter willen (en leuker)
                </h3>
                <h2 className="text-center text-active mb-3">
                  Met Astrid Elburg!
                </h2>
              </div>
              <div className="button-wrapper">
                <Link
                  to="#register"
                  className="video-btn aheto-btn js-video-btn aheto-btn--primary aheto-btn--large"
                >
                  Register
                </Link>
              </div>
            </div>
            <div className="landing-image"></div>
            <div className="landing-image event"></div>
            <div className="landing-image event-word"></div>
          </section>
          <section className="newestpod container-lg">
            <div className="inside fs-5 text-left background-dark p-5 shadow-light">
              <div className="flex-column-reverse flex-lg-row row">
                <div className="col-12 col-lg-9">
                  <p>
                    Je bent een leider in de zorg. Je werk levert je veel gedoe
                    en stress op. Deels omdat je culturele achtergrond een rol
                    speelt, maar ook omdat mensen gewoon lastig zijn (toch?).
                  </p>
                  <p>
                    Terwijl je juist iemand bent die energie wil geven. Die wil
                    inspireren! Fijn samenwerken, uitstekende zorg wil bieden in
                    een inclusieve werkvloer en wereld!
                  </p>
                  <p>
                    Als jij je hierin herkent hebben we iets heel tofs voor je!
                  </p>
                  <p>
                    Wij zijn Sara en Brigitte. En wij zijn managers in de zorg
                    net zoals jij.
                  </p>
                  <p>
                    We zien onszelf en ook onze collega’s stress hebben, drukte,
                    soms het gevoel overweldigd te zijn en vaker dan je lief is
                    conflicten hebben. Waardoor je haast structureel in de
                    verdediging schiet of verantwoording aan het afleggen bent.
                    Maar voor wat dan precies?
                  </p>
                  <p>
                    In januari 2022 geven we speciaal aan mensen als jij een tof
                    event over een sterke en inspirerende leider zijn in de
                    zorg!
                  </p>
                  <p>
                    Op 3 vrijdagochtenden gaan we het hebben over de dingen
                    die jij elke dag tegenkomt. En we hebben een speciale
                    trainer, de inspirerende Astrid Elburg!
                  </p>
                </div>
                <div className="col-12 d-flex col-lg-3 mb-lg-0 mb-4">
                  <img
                    className="m-auto w-full"
                    src="/uploads/Astridelburg-image-350.jpg"
                  ></img>
                </div>
              </div>
            </div>
          </section>
          <section className="imagetext">
            <div className="row">
              <div className="col-6 image">
                <img alt="" loading="lazy" src="/uploads/IMG_7847.jpg"></img>
              </div>
              <div className="col-12 col-xl-6 text">
                <div className="inside-wrapper">
                  <div className="inside fs-5">
                    <img
                      alt=""
                      loading="lazy"
                      src="/uploads/decoration.png"
                    ></img>
                    <h2 className="text-active fs-3 mt-5">
                      Want dit herken je vast:
                    </h2>
                    <ul className="bullet-active">
                      <li>
                        Er wordt vrijwel nooit een bal gedaan met dat wat jij zegt
                        (althans, heel vaak niet)
                      </li>
                      <li>
                        Je hebt het gevoel dat het management nauwelijks naar je
                        luistert
                      </li>
                      <li>
                        De ene dag denk je: laat dan maar, de andere dag val je
                        geïrriteerd uit naar collega’s. Meteen vinden je collega’s
                        je overheersend of dominant (is het dan nooit goed?!)
                      </li>
                      <li>
                        Je zit vaak bij vergaderingen en denkt: wat doe ik hier!
                        Waste of time. (en je weet niet goed hoe je dan aangeeft
                        dat je er gewoon niet bij wil zijn)
                      </li>
                      <li>
                        Je zit vaak bij vergaderingen en denkt: wat doe ik hier!
                        Waste of time. (en je weet niet goed hoe je dan aangeeft
                        dat je er gewoon niet bij wil zijn)
                      </li>
                      <li>
                        Je wil iets veranderen en je hebt het gevoel dat je tegen
                        de gevestigde (witte) orde zit te vechten
                      </li>
                    </ul>
                    <p className="mt-5">
                      Wij, Sara en Brigitte, herkennen dit maar al te goed. En
                      omdat we geloven dat het leuker en beter kan - en gelijker
                      - organiseren we dit event:{" "}
                      <b>
                        Inclusie in de zorg: een event voor leiders die het
                        beter willen.
                      </b>
                    </p>
                    <p>Dit kun je verwachten tijdens het event:</p>
                    <p>
                      Astrid Elburg is de persoon als docent en trainer als het
                      gaat om Bi-Cultureel Leiderschap in Nederland. Zij heeft
                      haar eigen vak op de VU. Ze is lid van diverse raden van
                      toezicht, onder andere van VPRO, Stadsarchief Amsterdam en
                      voorzitter van de RvT van het RIjksmuseum.
                    </p>
                    <p>
                      Hoe trots we zijn dat Astrid ons drie ochtenden gaat
                      inspireren? HEEL TROTS!
                    </p>
                    <p>
                      Dit is een powervrouw en zij weet hoe het werkt met een
                      echte leider zijn, wat je moet kunnen, juist als je van
                      kleur bent.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="newestpod container-lg">
            <div className="inside">
              <blockquote className="text-left">
                <h2 className="text-active fs-3">We gaan aan de slag met:</h2>
                <ul className="bullet-active">
                  <li>
                    Concrete vaardigheden om in gesprekken sterker over te komen
                    zodat je je doel bereikt én de sfeer goed blijft. Wat zeg je
                    wel en niet? En vooral: hoe zeg je het?
                  </li>
                  <li>
                    Hoe zorg je dat mensen, je collega teamleiders en management
                    jou de dingen gunnen?
                  </li>
                  <li>
                    Hoe ga je om met het gegeven dat je kleur ertoe doet en dat
                    dat invloed heeft op je werk en hoe mensen met je omgaan?
                  </li>
                  <li>
                    Hoe voeg je waarde toe? Hoe zorg je bijvoorbeeld dat er 4
                    vergaderingen per week worden geschrapt omdat niemand er op
                    zit te wachten?
                  </li>
                </ul>
                <h2 className="text-active fs-3 mt-5">Hoe doen we dit?</h2>
                <ul className="bullet-active">
                  <li>
                    Astrid levert theorieën, ze vertelt, laat voorbeelden zien
                    uit haar eigen brede ervaring als het gaat om samenwerken en
                    inspirerend zijn voor anderen
                  </li>
                  <li>
                    We doen oefeningen met concrete en herkenbare situaties van
                    de werkvloer (en vaak hilarisch en soms pijnlijk
                    herkenbaar?)
                  </li>
                  <li>
                    Er is veel ruimte voor eigen persoonlijke inbreng, want jouw
                    situatie is natuurlijk weer anders
                  </li>
                  <li>
                    In groepjes uit een om over specifieke situaties te oefenen
                    met effectief gedrag: elkaar concrete tips geven en ook
                    Astrid geeft daarin concrete tips
                  </li>
                </ul>
                <h2 className="text-active fs-3 mt-5">
                  Resultaat van dit event voor jou:
                </h2>
                <ul className="bullet-active">
                  <li>
                    je zit vol energie en hebt een bak ideeën om je werk weer
                    leuk te maken
                  </li>
                  <li>
                    je weet hoe je omgaat met collega’s die lastig zijn of
                    vooroordelen hebben
                  </li>
                  <li>
                    je hebt inzicht in je eigen aandeel in gedoe. Wat kan jij
                    zelf anders doen?
                  </li>
                  <li>
                    je hebt veel ideeën hoe jij zelf inspirerend bent voor
                    anderen
                  </li>
                  <li>Je weet je wat jouw kwaliteiten zijn als leider</li>
                  <li>
                    Je bent eigenaar van je eigen ontwikkelvraagstuk in een
                    dynamische omgeving
                  </li>
                </ul>
                <p className="mt-5">
                  BELANGRIJK: dit event is voor leiders die tegen confrontatie
                  kunnen. Het wordt leuk, inspirerend, er mag (moet!) gelachen
                  worden, maar je komt jezelf misschien ook wel tegen. Astrid is
                  een duidelijke vrouw en zegt waar het op staat! (we love it!)
                </p>
              </blockquote>
            </div>
          </section>
          <section className="imagetext register" id="register">
            <div className="row by-active">
              <div className="col-12 col-xl-7 text px-0">
                <div className="inside-wrapper">
                  <div className="inside fs-5">
                    <img
                      alt=""
                      height="60"
                      width="60"
                      loading="lazy"
                      src="/uploads/microphone.svg"
                    ></img>
                    <h2 className="fs-3">Bij zijn?</h2>
                    <p className="mb-1">
                      Dit event vindt plaats op 11 februari, 25 februari en 11
                      maart van 11.00 - 15.00 aansluitend een lunch!
                    </p>
                    <p>
                      Locatie: theater Altstadt Rotterdam.
                      <br />
                      Aelbrechtskade 9 Rotterdam
                    </p>
                    <RegisterForm />
                  </div>
                </div>
              </div>
              <div className="col-5 image px-0">
                <img alt="" loading="lazy" src="/uploads/thumbnail.jpg"></img>
              </div>
            </div>
          </section>
        </div>
      ) : null}
      {frontmatter.pageKey === "index" ? (
        <div>
          <section className="landing">
            <div className="inner-layer landing-inner">
              <div className="vertical-line"></div>
              <img alt="" loading="lazy" src="/uploads/logo_light.svg"></img>
              <h1>Zo hoort het</h1>
              <div className="button-wrapper">
                {/* Video button disabled until more info from client */}
                {/* <button
                  type="button"
                  className="video-btn aheto-btn js-video-btn aheto-btn--primary aheto-btn--large"
                >
                  Video
                </button> */}
                <Link
                  to="/podcasts/"
                  className="video-btn aheto-btn js-video-btn aheto-btn--primary aheto-btn--large"
                >
                  Podcasts
                </Link>
              </div>
            </div>
            <div className="landing-image"></div>
          </section>
          <section className="newestpod container-lg">
            <div className="inside">
              <h2>Latest podcast</h2>
              <h6>{frontmatter.new_pod_subtitle}</h6>
              {data.allMarkdownRemark.edges
                .slice(0, 3)
                .map((podcast, index) => (
                  <div key={index} className="new-podcast-wrapper">
                    <div className="podcast-inner">
                      <img
                        alt=""
                        loading="lazy"
                        className="dec-icon"
                        src="/uploads/podcast.svg"
                      ></img>
                      <div className="text">
                        {podcast.node.frontmatter.title}
                      </div>
                    </div>
                    <Link
                      to={podcast.node.fields.slug}
                      className="aheto-btn js-video-btn aheto-btn--primary aheto-btn--large"
                    >
                      Nu luisteren
                    </Link>
                  </div>
                ))}
            </div>
          </section>
          <section className="imagetext">
            <div className="row">
              <div className="col-6 image">
                <img alt="" loading="lazy" src={frontmatter.thumbnail}></img>
              </div>
              <div className="col-12 col-xl-6 text">
                <div className="inside-wrapper">
                  <div className="inside">
                    <img
                      alt=""
                      loading="lazy"
                      src="/uploads/decoration.png"
                    ></img>
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={{ __html: markedText }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : null}
      {frontmatter.pageKey === "about" ? (
        <div className="topspacer">
          <section className="imagestext">
            <div className="photoGallery container-lg">
              <div className="about">
                <img
                  alt=""
                  loading="lazy"
                  className="dec-icon"
                  src="/uploads/about.svg"
                ></img>
                <h2>{frontmatter.text_title}</h2>
                <h6>{frontmatter.text_subtitle}</h6>
                <blockquote>
                    <div className="p" dangerouslySetInnerHTML={{ __html: markedText }}></div>
                </blockquote>
                <div className="about-info row">
                  <div className="col-md-6">
                    <h6>{frontmatter.brigitte_info}</h6>
                    <a href={"mailto:" + frontmatter.brigitte_email}>
                      <h6>{frontmatter.brigitte_email}</h6>
                    </a>
                    <div className="bullet-list">
                      {frontmatter.brigitte_list}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h6>{frontmatter.sara_info}</h6>
                    <a href={"mailto:" + frontmatter.sara_email}>
                      <h6>{frontmatter.sara_email}</h6>
                    </a>
                    <div className="bullet-list">{frontmatter.sara_list}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                {frontmatter.galleryImages.map((galleryImg, index) => (
                  <div key={index} className="col-12 col-xl-6">
                    <img alt="" loading="lazy" src={"/" + galleryImg}></img>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      ) : null}
      {frontmatter.pageKey === "podcasts" ? (
        <div className="topspacer">
          <section className="podcasts container-lg">
            <div className="vertical-line"></div>
            <h1>{frontmatter.text_title}</h1>
            <blockquote>
              <p>{frontmatter.text}</p>
            </blockquote>
            <div className="podcast-list">
              <div className="podcast-list-head">
                <div className="inner">
                  <div id="cat-all" className="category-list selected">
                    Alle
                  </div>
                  {seasons.map((season, index) => (
                    <div
                      key={index}
                      className="category-list"
                      id={`Season${season}`}
                    >
                      Season {season}
                    </div>
                  ))}
                </div>
              </div>
              <div className="podcast-list-body row">
                {data.allMarkdownRemark.edges.map((podcasts, index) => (
                  <div
                    key={index}
                    className={`col-md-6 cat-all Season${podcasts.node.frontmatter.season}`}
                  >
                    <Link
                      to={podcasts.node.fields.slug}
                      className="podcast-inner"
                    >
                      <h5>{podcasts.node.frontmatter.title}</h5>
                      <p>{podcasts.node.frontmatter.text}</p>
                      <h6>
                        <div>
                          S{podcasts.node.frontmatter.season}E
                          {podcasts.node.frontmatter.episode}
                        </div>
                        Nu luisteren!
                      </h6>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      ) : null}
      {frontmatter.pageKey === "contact" ? (
        <div className="topspacer">
          <section className="imagestext">
            <div className="photoGallery container-lg">
              <div className="about contact-inner">
                <img
                  alt=""
                  loading="lazy"
                  className="dec-icon"
                  src="/uploads/contactnew.svg"
                ></img>
                <h2>{frontmatter.text_title}</h2>
                <h6>{frontmatter.text_subtitle}</h6>
              </div>
            </div>
          </section>
          <ContactSection></ContactSection>
        </div>
      ) : null}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        main_video
        pageKey
        galleryImages
        text
        text_title
        text_subtitle
        thumbnail
        brigitte_info
        brigitte_email
        brigitte_list
        sara_info
        sara_email
        sara_list
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/podcasts/../" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            text
            episode
            season
            date
          }
        }
      }
    }
  }
`;
