import React from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

registerLocale("nl", nl);
export default class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      birth: null
    };

    this._handleSubmit = this._handleSubmit.bind(this);
    this._unexpectedError = this._unexpectedError.bind(this);
    this._missingFields = this._missingFields.bind(this);
    this._successResponse = this._successResponse.bind(this);
    this._checkRegistered = this._checkRegistered.bind(this);
  }

  componentDidMount() {
    this._checkRegistered();
  }

  _unexpectedError() {
    toast.error("Sorry, onverwachte fout opgetreden. Mocht je dit bericht vaker zien, neem contact met ons op.", {
      position: "bottom-left",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }

  _missingFields(error) {
    toast.error(
      <div>
        Niet alle verplichte velden zijn ingevuld.
        <ul className="fs-6 text-left">
          {error.response.data.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>,
      {
        position: "bottom-left",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      }
    );
  }

  _successResponse(response) {
    if (response.data.success) {
      toast.success(response.data.message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });

      if (response.data.redirect && response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
      }
    } else {
      this.unexpectedError();
    }
  }

  _handleSubmit(e) {
    e.preventDefault();
    toast.dismiss();
    let $this = this;

    axios({
      method: "post",
      url: "/api/event/index.php",
      data: {
        is_ticket: e.target.paymentType.value === "1" ? true : false,
        is_executive: e.target.executive.value === "1" ? true : false,
        first_name: e.target.formName.value,
        middle_name: e.target.formMiddleName.value,
        last_name: e.target.formLastName.value,
        birth: e.target.formBirth.value,
        email: e.target.formEmail.value,
        address: e.target.formAddress.value,
        postcode: e.target.formPostcode.value,
        city: e.target.formCity.value,
        payment_email: e.target.formEmailPayment.value,
        work: e.target.formWork.value,
        message: e.target.formMsg.value
      }
    })
      .then(function(response) {
        e.target.reset();

        $this._successResponse(response);
      })
      .catch(function(error) {
        console.log(error);
        if (error.response && error.response.status === 422) {
          $this._missingFields(error);
        } else {
          $this._unexpectedError(error);
        }
      });
  }

  _checkRegistered() {
    var $search = new URLSearchParams(document.location.search).get("register");

    if ($search === "success") {
      toast.success("Aangemeld, zie je mailbox voor de bevestiging.", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }

  render() {
    return (
      <>
        <ToastContainer
          theme="dark"
          position="bottom-left"
          autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <form
          className="form register-form"
          onSubmit={this._handleSubmit}
          id="formContact"
        >
          <div className="d-flex">
            <div className="position-relative">
              <input
                className="left"
                type="radio"
                value="0"
                id="invoice"
                name="paymentType"
                required
              />
              <label className="w-100 button" htmlFor="invoice">
                YES IK BEN ERBIJ. Ik betaal op factuur.
              </label>
            </div>
            <div className="position-relative">
            <input
              className="right"
              type="radio"
              value="1"
              id="ideal"
              name="paymentType"
              required
            />
            <label className="w-100 button" htmlFor="ideal">
              YES IK BEN ERBIJ. Ik betaal via iDEAL.
            </label>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <label className="form-label" htmlFor="formName">
                Voornaam
              </label>
              <input
                id="formName"
                type="text"
                name="formName"
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="form-label" htmlFor="formMiddleName">
                Tussenvoegsel
              </label>
              <input
                id="formMiddleName"
                type="text"
                name="formMiddleName"
                className="form-control"
              />
            </div>
            <div className="col-12 mt-1">
              <label className="form-label" htmlFor="formLastName">
                Achternaam
              </label>
              <input
                id="formLastName"
                type="text"
                name="formLastName"
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-1">
              <label className="form-label" htmlFor="formBirth">
                Geboortedatum
              </label>
              <DatePicker
                locale="nl"
                id="formBirth"
                name="formBirth"
                required
                className="form-control"
                selected={this.state.birth}
                onChange={date => this.setState({ birth: date })}
                showYearDropdown
                autoComplete="off"
              />
            </div>
            <div className="col-12 col-md-6 mt-1">
              <label className="form-label" htmlFor="formEmail">
                E-mailadres
              </label>
              <input
                id="formEmail"
                type="email"
                name="formEmail"
                required
                className="form-control"
              />
            </div>

            <div className="col-12 col-md-6 mt-4">
              <label className="form-label" htmlFor="formAddress">
                Adres
              </label>
              <input
                id="formAddress"
                type="text"
                name="formAddress"
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-1 mt-md-4">
              <label className="form-label" htmlFor="formPostcode">
                Postcode
              </label>
              <input
                id="formPostcode"
                type="text"
                name="formPostcode"
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-1">
              <label className="form-label" htmlFor="formCity">
                Plaats
              </label>
              <input
                id="formCity"
                type="text"
                name="formCity"
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-1">
              <label className="form-label" htmlFor="formEmailPayment">
                E-mailadres factuur
              </label>
              <input
                id="formEmailPayment"
                type="email"
                name="formEmailPayment"
                required
                className="form-control"
              />
            </div>

            <div className="col-12 col-md-6 mt-4">
              <label className="form-label" htmlFor="formWork">
                In welke sector werk je?
              </label>
              <input
                id="formWork"
                type="text"
                name="formWork"
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 mt-1 mt-md-4 d-flex flex-column">
              <label className="form-label mt-04" htmlFor="formExecutive">
                Ben je leidinggevenden?
              </label>
              <div className="d-flex w-100 h-100">
                <input
                  className="left"
                  type="radio"
                  name="executive"
                  value="1"
                  id="executiveTrue"
                  required
                />
                <label className="w-100 button" htmlFor="executiveTrue">Ja</label>
                <input
                  className="right"
                  type="radio"
                  name="executive"
                  value="0"
                  id="executiveFalse"
                  required
                />
                <label className="w-100 button" htmlFor="executiveFalse">Nee</label>
              </div>
            </div>

            <div className="col-12">
              <label className="form-label" htmlFor="formMsg">
                Wat zou jij het liefst willen leren?
              </label>
              <textarea
                className="form-control"
                id="formMsg"
                name="formMsg"
                rows="4"
                cols="40"
              ></textarea>
            </div>
          </div>
          <div className="d-flex mt-4">
            <p className="my-auto">Kosten: 549 euro (excl. BTW)</p>
            <input
              type="submit"
              value={
                this.state.isTicket ? "Koop ticket!" : "Factuur verzenden!"
              }
              className="video-btn aheto-btn js-video-btn aheto-btn--primary ms-auto"
              id="btn-submit"
            />
          </div>
        </form>
      </>
    );
  }
}
